const dropDownContainers = document.querySelectorAll('[data-drop-down-container]');

if (dropDownContainers) {
  dropDownContainers.forEach((dropDownContainer) => {
    const dropDownButton = dropDownContainer.querySelector('[data-drop-down-button]');
    const dropDown = dropDownContainer.querySelector('[data-drop-down]');
    const dropDownList = dropDown.querySelector('[data-drop-down-list]');
    const dropDownPreviews = dropDownButton.querySelectorAll('[data-drop-down-preview]');
    const dropDownImages = dropDownContainer.querySelectorAll('[data-drop-down-image]');
    const dropDownName = dropDownButton.querySelector('[data-drop-down-name]');
    const dropDownItems = dropDownList.querySelectorAll('[data-drop-down-item]');
    const dropDownDescriptions = dropDownContainer.querySelectorAll('[data-drop-down-description]');

    dropDownButton.addEventListener('click', () => {
      dropDownList.classList.toggle('_active');
    }, { bubbles: true });

    const removeClasses = (items) => {
      items.forEach((item) => {
        item.classList.remove('_active');
      });
    };

    const addActiveClass = (items, target) => {
      items.forEach((item, key) => {
        if (key === target) {
          item.classList.add('_active');
        }
      });
    };

    dropDownItems.forEach((item) => {
      item.addEventListener('click', () => {
        removeClasses(dropDownPreviews);
        removeClasses(dropDownImages);
        removeClasses(dropDownDescriptions);
        addActiveClass(dropDownPreviews, Number(item.dataset.dropDownItem));
        addActiveClass(dropDownImages, Number(item.dataset.dropDownItem));
        addActiveClass(dropDownDescriptions, Number(item.dataset.dropDownItem));
        dropDownList.classList.remove('_active');
        dropDownName.innerHTML = item.querySelector('[data-drop-down-name]').innerHTML;
      });

      document.addEventListener('click', (e) => {
        if (event.target !== dropDownButton) {
          dropDownList.classList.remove('_active');
        }
      });
    });
  });
}
import LiveEvent from '../../components/live/live.js';

const handler = function floatingFieldHandler() {
  if (this.value) {
    this.parentNode.classList.add('_filled');
  } else {
    this.parentNode.classList.remove('_filled');
  }
};

new LiveEvent('keyup', '[data-floating-field] input, [data-floating-field] textarea', handler);

document.addEventListener('success', () => {
  const fields = document.querySelectorAll('._filled');
  for (const field of fields) {
    field.classList.remove('_filled');
  }
});
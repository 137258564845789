const addRemoveField = () => {
  document.querySelectorAll('[data-add]').forEach((block) => {
    const button = block.querySelector('[data-add-button]');
    const container = block.querySelector('[data-add-container]');
    const fields = container ? container.querySelectorAll('[data-add-field]') : null;
    const field = container ? container.querySelector('[data-add-field]') : null;

    fields.forEach((field) => {
      field.querySelector('[data-remove-button]').addEventListener('click', () => {
        field.parentNode.removeChild(field);
        checkCount();
      });
    });

    button.addEventListener('click', () => {
      const newField = field ? field.cloneNode(true) : null;
      if (newField) {
        newField.querySelector('input[type="text"]').value = null;
        newField.querySelector('[data-floating-field]').classList.remove('_filled');

        const remover = newField ? newField.querySelector('[data-remove-button]') : null;
        if (remover) {
          remover.addEventListener('click', () => {
            newField.parentNode.removeChild(newField);
            checkCount();
          });

          container.appendChild(newField);
          checkCount();
        }
      }
    });

    const checkCount = () => {
      if (container.querySelectorAll('[data-add-field]').length > 1) {
        container.classList.remove('_hide-remover');
      } else {
        container.classList.add('_hide-remover');
      }
    }
  })
};

document.addEventListener('DOMContentLoaded', addRemoveField);
const bind = () => {
    const switchButtons = document.querySelectorAll('[data-switch-button]');
    const switchTargets = document.querySelectorAll('[data-switch-target]');

    switchTargets.forEach((target) => {
        target.style.transitionDuration = '400ms';
        target.style.transitionTimingFunction = 'easy-in-out';
        target.style.height = '0';
    });

    switchButtons.forEach((button) => {
        button.addEventListener('click', () => {
            switchTargets.forEach((target) => {
                if (button.dataset.switchButton === target.dataset.switchTarget) {
                    target.style.height = button.classList.contains('_active') ? 0 : `${target.childNodes[1].offsetHeight}px`;
                    button.classList.toggle('_active');
                    console.log(`${target.childNodes[1].offsetHeight}px`)
                }
            });
        })
    });
};

document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('DOMContentMutated', bind);
import Jax from '../../components/jax/jax';

class FilterForm {
  constructor(options) {
    this.options = {
      form: '[data-filter-form]',
      content: '[data-filter-data]',
      trackedFields: '.checkbox-list input, .radio-list input, .sort-input input, select, input',
    };
    this.options = Object.assign({}, this.options, options);
  }

  init(form) {
    this.bind(form);
  }

  bind(form) {
    const trackedFields = form.querySelectorAll(this.options.trackedFields);

    if (trackedFields) {
      trackedFields.forEach((field) => {
        field.addEventListener('change', () => {
          this.updateForm(form);
        });
      });
    }
  }

  updateForm(form) {
    const request = new Jax();
    const formData = new FormData(form);

    request.send(formData)
      .then((page) => {
        this.replaceContent(page.data);
      });
  }

  replaceContent(page) {
    const pageElement = document.createElement('div');
    const content = document.querySelector(this.options.content);
    pageElement.innerHTML = page;
    content.parentNode.replaceChild(pageElement.querySelector(this.options.content), content);
    document.dispatchEvent(new Event('DOMContentMutated'));
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector('[data-filter-form]');
  if (form) {
    const filterForm = new FilterForm();
    filterForm.init(form);
  }
});
function init() {
  document.querySelectorAll('[data-form-file-field]')
    .forEach((field) => {
      field.addEventListener('change', (e) => {
        e.preventDefault();
        if (e.target.value) {
          field.classList.add('_filled');
        }
      });

      removeButton = field.querySelector('[data-form-file-field-remove]');
      removeButton.addEventListener('click', () => {
        field.classList.remove('_filled');
        field.querySelector('input[type=file]').value = '';
      })
    });
}

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('DOMContentMutated', init);
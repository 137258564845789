import { gsap } from 'gsap';
import _ from 'lodash';

const bind = () => {
  const buttons = document.querySelectorAll('[data-categories-name]');
  const pictures = document.querySelectorAll('[data-categories-item]');
  const links = document.querySelectorAll('[data-categories-link]');

  if (links[0]) {
    links.forEach((link) => {
      link.style.color = '#000';
      link.addEventListener('mouseover', () => {
        links.forEach((item) => {
          item.style.color = '';
        });
      });
    });
  }

  if (buttons[0] && pictures[0] && window.innerWidth >= 768) {
    const removeClasses = (items, itemClass) => {
      items.forEach((item) => {
        item.classList.remove(itemClass);
      });
    };

    let countHover = 1;

    const wait = _.debounce((button) => {
      pictures.forEach((picture) => {
        if (picture.dataset.categoriesItem === button.dataset.categoriesName && !picture.classList.contains('_active')) {
          removeClasses(pictures, '_active');

          picture.style.zIndex = countHover++;
          gsap.fromTo(picture, {
            x: picture.offsetWidth,
            duration: 0,
          }, {
            x: '0',
            duration: 0.7,
            animationTimingFunction: 'easy-in-out',
            repeatDelay: 0.7,
          });
          gsap.fromTo(picture.childNodes[1], {
            x: -picture.offsetWidth,
            duration: 0,
          }, {
            x: '0',
            duration: 0.7,
            animationTimingFunction: 'easy-in-out',
            repeatDelay: 0.7,
          });
          picture.classList.add('_active');
        }
      });
    }, 300);

    buttons.forEach((button) => {
      button.addEventListener('mouseover', () => {
        wait(button);
      });
    });
  }
};



document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('DOMContentMutated', bind);
document.addEventListener('resize', bind);
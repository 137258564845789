import { throttle } from 'lodash';

class ColorSwitch {
  constructor() {
    this.options = {
      contain: '[data-color-contain]',
      displays: '[data-color-display]',
      colorNames: '[data-color-name]',
    };
  }

  init() {
    this.bind();
  }

  bind() {
    const removeClasses = (items) => {
      items.forEach((item) => {
        item.classList.remove('_active');
      });
    };
    const colorChange = (displays, color) => {
      displays.forEach((display) => {
        if (display.dataset.colorDisplay === color.dataset.colorName) {
          display.classList.add('_active');
          color.classList.add('_active');
        }
      });
    };
    const animate = (colorNames, colorDisplays, colorName) => {
      removeClasses(colorNames);
      removeClasses(colorDisplays);
      colorChange(colorDisplays, colorName);
    };
    const execute = throttle(animate, 900);

    const contain = document.querySelector(this.options.contain);
    if (contain) {
      const colorNames = contain.querySelectorAll(this.options.colorNames);
      const colorDisplays = contain.querySelectorAll(this.options.displays);
      colorNames.forEach((colorName) => {
        colorName.addEventListener('click', () => {
          execute(colorNames, colorDisplays, colorName);
        });
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const colorSwitch = new ColorSwitch();
  colorSwitch.init();
});
class Header {
  constructor() {
    this.options = {
      header: '[data-header]',
      visibleClass: 'header_visible',
      stickyClass: 'header_sticky',
      offsetVisible: 150,
      transitionDuration: 400,
      buttonActiveMenu: '[data-menu-toggle]',
      stickyItems: '[data-sticky-item]',
      mainPage: false,
    };
  }

  init() {
    this.bind();
  }

  bind() {
    if(window.innerWidth <= 767) {
      this.options.offsetVisible = 65;
    }
    const addStickyClass = (stickyBlocks, currentScrollTop, header) => {
      stickyBlocks.forEach((stickyBlock) => {
        if (stickyBlock.offsetTop <= currentScrollTop + header.offsetHeight / 2) {
          stickyBlock.style.transitionDuration = '400ms';
          stickyBlock.style.top = `${header.offsetHeight / 2}px`;
        }
      });
    };
    const removeStickyClass = (stickyBlocks) => {
      stickyBlocks.forEach((stickyBlock) => {
        stickyBlock.style.top = 0;
      });
    };
    const addHeaderLightClass = (header) => {
      if (this.options.mainPage === true) {
        header.classList.add('header_light');
      }
    };
    const header = document.querySelector(this.options.header);
    if (header) {
      let tempScrollTop;
      let currentScrollTop = 0;
      const stickyBlocks = document.querySelectorAll(this.options.stickyItems);
      stickyBlocks.forEach((stickyBlock) => {
        stickyBlock.style.transitionDuration = '400ms';
        stickyBlock.style.transitionTimingFunction = 'ease-in-out';
      });
      document.addEventListener('scroll', () => {
        currentScrollTop = window.pageYOffset;
        if (currentScrollTop > this.options.offsetVisible) {
          header.classList.add(this.options.stickyClass);
          if (header.classList.contains('header_light')) {
            header.classList.remove('header_light');
            header.style.position = 'fixed';
            this.options.mainPage = true;
          }
          if (!header.classList.contains(this.options.visibleClass) && currentScrollTop > tempScrollTop) {
            header.style.transitionDuration = '0ms';
          }
          if (currentScrollTop < (this.options.offsetVisible + 850)) {
            header.classList.remove(this.options.visibleClass);
            removeStickyClass(stickyBlocks);
          }
        } else {
          header.style.transitionDuration = '0ms';
          header.classList.remove(this.options.visibleClass);
          header.classList.remove(this.options.stickyClass);
          addHeaderLightClass(header);
          removeStickyClass(stickyBlocks);
          if (header.classList.contains('header_light')) {
            header.style.position = 'absolute';
          }
        }
        if (header.classList.contains(this.options.stickyClass) && currentScrollTop > (this.options.offsetVisible + 850)) {
          if (currentScrollTop < tempScrollTop) {
            header.style.transitionDuration = `${this.options.transitionDuration}ms`;
            header.classList.add(this.options.visibleClass);
            addStickyClass(stickyBlocks, currentScrollTop, header);
          } else {
            header.classList.remove(this.options.visibleClass);
            removeStickyClass(stickyBlocks);
          }
        }
        stickyBlocks.forEach((stickyBlock) => {
          if (stickyBlock.offsetTop > currentScrollTop + 72) {
            removeStickyClass(stickyBlocks);
          }
        });
        tempScrollTop = currentScrollTop;
      });
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const header = new Header();
  header.init();
});

document.addEventListener('resize', () => {
  const header = new Header();
  header.init();
});
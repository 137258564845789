const bind = () => {
  const catalogItems = document.querySelectorAll('[data-catalog-item]');
  catalogItems.forEach((catalogItem) => {
    const catalogItemLinks = catalogItem.querySelectorAll('[data-catalog-item-link]');

    catalogItemLinks.forEach((catalogItemLink, key) => {
      const catalogItemText = catalogItemLink.querySelector('[data-catalog-item-text]');

      catalogItemText.style.transitionDelay = `${key * 100 + 100}ms`;
    });
  });
};

document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('DOMContentMutated', bind);
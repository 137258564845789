import { gsap, Linear } from 'gsap';
import { ScrollScene, ScrollMagic } from 'scrollscene';

const bind = () => {
    setTimeout(function ()  {
        const controller = new ScrollMagic.Controller();
        const slider = document.querySelector('[data-parallax-slider]');
        if (slider) {
            const list = document.querySelector('[data-parallax-list]');
            const texts = slider.querySelectorAll('[data-parallax-text]');
            const filter = slider.querySelectorAll('.main-slider__filter_black');

            const sliderHeight = list.offsetHeight;
            const calculate = () => {
                const topBorderPosition = slider.getBoundingClientRect().top;
                const preOffset = (window.innerHeight - topBorderPosition - slider.offsetHeight);
                const translateOffset = preOffset / 3;
                list.style.transform = 'translateY(' + translateOffset + 'px)';
                window.requestAnimationFrame(() => calculate());
            };
            if (window.innerWidth >= 1200) {
                calculate();
                let tl = gsap.timeline();
                texts.forEach((text) => {
                    tl.to(text, {
                        y: sliderHeight * 0.1,
                        ease: Linear.easeNone
                    }, "<");
                });
                const scene = new ScrollScene({
                    triggerElement: slider,
                    triggerHook: 0,
                    duration: '100%',
                    gsap: {
                        timeline: tl,
                    },
                });
                scene.Scene
                //.addIndicators()
                  .addTo(controller);
            }

            let tlFilter = gsap.timeline();
            tlFilter.to(filter, {
                opacity: 0.8,
                ease: Linear.easeNone
            }, "<");
            const sceneFilter = new ScrollScene({
                triggerElement: slider,
                triggerHook: 0,
                duration: '33%',
                gsap: {
                    timeline: tlFilter,
                },
            });
            sceneFilter.Scene
            //.addIndicators()
              .addTo(controller);
        }
    }, 700);
};

document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('DOMContentMutated', bind);
document.addEventListener('resize', bind);
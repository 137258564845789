const bind = () => {
  const categoriesItems = document.querySelectorAll('[data-menu-children]');
  categoriesItems.forEach((categoriesItem) => {
    const projectItems = categoriesItem.querySelectorAll('li');
    projectItems.forEach((projectItem, key) => {
      projectItem.style.transitionDelay = `${key * 100 + 100}ms`;
    });
  });
};

document.addEventListener('DOMContentLoaded', bind);
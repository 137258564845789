function initMapGoogle() {
  /*
  let infowindow = new google.maps.InfoWindow({
    content: '<div class="contacts-lines popup">' + $('.contacts-lines').html() + '</div>'
  });
  */

  const customMapType = new google.maps.StyledMapType([
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e9e9e9',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 17,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 29,
        },
        {
          weight: 0.2,
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 18,
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dedede',
        },
        {
          lightness: 21,
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'on',
        },
        {
          color: '#ffffff',
        },
        {
          lightness: 16,
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: 36,
        },
        {
          color: '#333333',
        },
        {
          lightness: 40,
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [
        {
          color: '#f2f2f2',
        },
        {
          lightness: 19,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#fefefe',
        },
        {
          lightness: 17,
        },
        {
          weight: 1.2,
        },
      ],
    },
  ], {
    name: 'Custom Style',
  });
  const customMapTypeId = 'custom_style';

  document.querySelectorAll('[data-map]').forEach((mapElement) => {
    const map = new google.maps.Map(mapElement, {
      zoom: 14,
      center: {
        lat: parseFloat(mapElement.dataset.lat),
        lng: parseFloat(mapElement.dataset.lng),
      },
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, customMapTypeId],
      },
      panControl: false,
      zoomControl: true,
      scaleControl: false,
      streetViewControl: false,
      scrollwheel: false,
      mapTypeControl: false,
    });

    // map.mapTypes.set(customMapTypeId, customMapType);
    // map.setMapTypeId(customMapTypeId);

    const marker = new google.maps.Marker({
      position: {
        lat: parseFloat(mapElement.dataset.lat),
        lng: parseFloat(mapElement.dataset.lng),
      },
      icon: mapElement.dataset.mark,
      map,
    });
  });
  /*
  marker.addListener('click', function() {
    infowindow.open(map, marker);
  });
  */
}

function initMapYandex() {
  document.querySelectorAll('[data-map]').forEach((mapElement) => {

    // отображение маршрута от то А до B через промежуточные координаты
    const myPolyline = new ymaps.Polyline([
      [55.7692094848979,37.595040972737365],
      [55.76795145100374,37.59327071478639],
      [55.766191344966074,37.59082454016353],
      [55.76599778869872,37.5905563192616],
      [55.76630626734165,37.58957999517889],
      [55.76651191842315,37.58927958776882],
      [55.76703209045411,37.58858221342356],
      [55.76714096228306,37.588421280881825],
      [55.76734056051293,37.58898990919229],
      [55.76779418946566,37.58954780866653],
    ], {},
    {
      strokeWidth: 3,
      strokeColor: '#0000FF',
      strokeDasharray: 2.5,
      draggable: false,
    });

    myPolyline.editor.startEditing();
    const mySecondPlacemark = new ymaps.GeoObject({
      geometry: {
        type: 'Point',
        coordinates: [55.76928294851043, 37.59500166356526],
      },
    });
    const myMap = new ymaps.Map(mapElement, {
      center: [parseFloat(mapElement.dataset.lat) - 0.001, parseFloat(mapElement.dataset.lng)],
      zoom: mapElement.dataset.zoom,
      controls: ['zoomControl', 'fullscreenControl'],
    }, {
      searchControlProvider: 'yandex#search',
    });

    const myPlacemark = new ymaps.Placemark([parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)], {}, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение иконки метки.
      iconImageHref: mapElement.dataset.mark,
      // Размеры метки.
      iconImageSize: [43, 57],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-21, -57],
    });
    // Добавляем мультимаршрут на карту.
    myMap.behaviors.disable('scrollZoom');
    if (window.innerWidth <= 1024) {
      myMap.behaviors.disable('drag');
    }
    myMap.geoObjects.add(myPlacemark);
    myMap.geoObjects.add(myPolyline);
    myMap.geoObjects.add(mySecondPlacemark);
  });
}
// google.maps.event.addDomListener(window, 'load', initMap);

window.addEventListener('load', () => {
  if (window.google) {
    initMapGoogle();
  }
  if (window.ymaps) {
    ymaps.ready(initMapYandex);
  }
});
import { ScrollScene } from 'scrollscene';
import { ScrollMagic } from 'scrollscene';

setTimeout(() => {
  const nav = document.querySelector('[data-sticky-item]');
  if (nav) {
    const topBlock = nav.querySelector('[data-sticky-top-block]');
    const bottomBlock = nav.querySelector('[data-sticky-bottom-block]');
    const triggers = document.querySelectorAll('[data-hide-trigger]');
    const controller = new ScrollMagic.Controller();

    triggers.forEach((trigger) => {
      const sceneHide = new ScrollScene({
        triggerElement: trigger,
        triggerHook: nav.offsetHeight / document.documentElement.clientHeight,
        duration: trigger.offsetHeight + nav.offsetHeight - 380,
      });
      sceneHide.Scene
        .setClassToggle(bottomBlock, '_hide')
        //.addIndicators()
        .addTo(controller);
    });

    triggers.forEach((trigger) => {
      const sceneHide = new ScrollScene({
        triggerElement: trigger,
        triggerHook: 0.6,
        duration: trigger.offsetHeight + 240 * document.documentElement.clientWidth / document.documentElement.clientHeight,
      });
      sceneHide.Scene
        .setClassToggle(topBlock, '_hide')
        //.addIndicators()
        .addTo(controller);
    });
  }
}, 1000);
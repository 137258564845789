document.addEventListener('DOMContentLoaded', () => {
  const videoId = document.querySelector('#player');
  if (videoId) {
    let player;

    const onPlayerReady = (event) => {
      event.target.playVideo();
      player.mute();
    };

    const onPlayerStateChange = () => {

    };

    window.YT.ready(() => {
      player = new window.YT.Player('player', {
        playerVars: {
          loop: 0,
          iv_load_policy: 3,
          autoplay: 1,
          controls: 0,
          playlist: videoId.innerHTML,
          rel: 0,
        },
        videoId: videoId.innerHTML,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    });

    const videoLink = document.querySelector('[data-video-link]');
    videoLink.addEventListener('click', () => {
      player.pauseVideo();
    });
    fsLightboxInstances['lightbox'].props.onClose = () => player.playVideo();
  }
});
import { gsap, Linear } from 'gsap';
import { ScrollScene, ScrollMagic } from 'scrollscene';

const bind = () => {
    const controller = new ScrollMagic.Controller();

    const parents = document.querySelectorAll('[data-parallax-parent]');
    parents.forEach((parent) => {
        const children = parent.querySelectorAll('[data-parallax-child]');
        children.forEach((child) => {
            const duration = child.dataset.parallaxDuration ? child.dataset.parallaxDuration : '100%';
            const offset = child.dataset.parallaxOffset ? child.dataset.parallaxOffset : '-100px';
            const length = child.dataset.parallaxLength ? child.dataset.parallaxLength : '20%';
            const triggerHook = child.dataset.parallaxHook ? child.dataset.parallaxHook : 1;

            let tl = gsap.timeline();
            tl.from(child, {
                y: length,
                ease: Linear.easeNone
            });

            const scene = new ScrollScene({
                triggerElement: parent,
                triggerHook: triggerHook,
                duration: duration,
                offset: offset,
                gsap: {
                    timeline: tl,
                },
            });
            scene.Scene
                //.addIndicators()
                .addTo(controller);
        });
    });
};

document.addEventListener('DOMContentLoaded', bind);
document.addEventListener('DOMContentMutated', bind);
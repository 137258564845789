import Office from './Office';
import { scrollToTarget } from '../../components/scroll/scroll';

export default class Offices {
    constructor(element) {
        this.rootElement = element;
        if (!element) {
            return;
        }
        this.settings = Object.assign({}, element.dataset);

        this.mapElement = element.querySelector('[data-offices-map]');
        this.map = undefined;
        this.placemark = undefined;

        this.offices = [];
        this.activeOffice = undefined;

        this.initMapPending();
    }

    isTablet() {
        return 767 < window.innerWidth && window.innerWidth < 1180;
    }

    isMobile() {
        return window.innerWidth < 768;
    }

    initMapPending() {
        if (window.ymaps) {
            this.initMap();
            return;
        }
        setTimeout(() => {
            this.initMapPending();
        }, 500);
    }

    initMap() {
        ymaps.ready(() => {
            this.initObjects();
            this.createMap();
        });
    }

    initObjects() {
        this.iconOptions = {
            iconImageSize: !this.isMobile() ? [52, 67] : [35, 45],
            iconImageOffset: !this.isMobile() ? [0, -86] : [0, -45],
        };

        this.rootElement.querySelectorAll('[data-list-item]')
            .forEach((listItemElement) => {
                const office = new Office(listItemElement);
                const placemark = this.initPlacemark([office.getLat(), office.getLng()]);

                office.setPlacemark(placemark);

                listItemElement.addEventListener('click', () => {
                    this.onListElementClick(office);
                });

                this.offices.push(office);
            });
    }

    initPlacemark(coords) {
        this.placemark = new ymaps.Placemark(coords, {}, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: this.mapElement.dataset.mark,
            // Размеры метки.
            iconImageSize: this.iconOptions.iconImageSize,
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: this.iconOptions.iconImageOffset,
            iconContentOffset: [0, 0],
        });
        return this.placemark;
    }

    createClusterer() {
        // Создание кластера и запрещение масштабирования карты при щелчке по кластеру
        this.clusterer = new ymaps.Clusterer({
            hasBalloon: false,
            clusterIcons: [
                {
                    href: '',
                    size: [45, 45],
                    offset: [0, 0],
                },
            ],
            clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
                `<div class="map__cluster-icon">{{ properties.geoObjects.length }}</div>`,
            ),
            clusterDisableClickZoom: true
        });
    }

    createMap() {
        const rect = this.mapElement.getBoundingClientRect();
        let bottom = 220;
        if (rect.height) {
            bottom = (rect.height / 2 - 30);
        }

        this.map = new ymaps.Map(this.mapElement, {
            center: [0, 0],
            zoom: 17,
            controls: ['zoomControl'],
        }, {
            zoomControlPosition: {right: 10, top: 'auto', left: 'auto', bottom: bottom + 'px'},
            zoomControlSize: 'small',
        });

        this.map.behaviors.disable('scrollZoom');
        if (this.isMobile() || this.isTablet()) {
            this.map.behaviors.disable('drag');
        }

        this.map.margin.setDefaultMargin(this.getMapMargin());

        // this.fitCenter();

        this.createClusterer();

        this.offices.map((office, index) => {
            this.clusterer.add(office.getPlacemark());
            this.map.geoObjects.add(this.clusterer);
        });

        if (this.offices[0]) {
            this.activateOffice(this.offices[0]);
        }
    }

    getCenterCoordsByAllVisibleOffices() {
        // Берем все координаты от всех точек
        let minLat;
        let minLng;
        let maxLat;
        let maxLng;

        this.offices.forEach((office) => {
            if (minLat === undefined || minLat > office.getLat()) {
                minLat = office.getLat();
            }
            if (maxLat === undefined || maxLat < office.getLat()) {
                maxLat = office.getLat();
            }

            if (minLng === undefined || minLng > office.getLng()) {
                minLng = office.getLng();
            }
            if (maxLng === undefined || maxLng < office.getLng()) {
                maxLng = office.getLng();
            }
        });

        return [[minLat, minLng], [maxLat, maxLng]];
    }

    fitCenter() {
        const coords = this.getCenterCoordsByAllVisibleOffices();
        let minLat = coords[0][0];
        let minLng = coords[0][1];
        let maxLat = coords[1][0];
        let maxLng = coords[1][1];
        if (minLat === maxLat && minLng === maxLng) {
            // Если офис один, то центрируем карту и берем зум от этого офиса
            this.moveMapCenterTo(
                [this.offices[0].getLat(), this.offices[0].getLng()],
                parseInt(this.offices[0].settings.zoom, 10)
            );
        } else {
            // Определяем центр и оптимальный зум
            ymaps.util.requireCenterAndZoom(
                this.map.getType(),
                coords,
                this.map.container.getSize(),
                {margin: this.getMapMargin()},
            ).then((result) => {
                this.map.setCenter(result.center, result.zoom, {duration: 500});
            });
        }
    }

    getMapMargin() {
        if (this.isMobile()) {
            return [31, 31, 31, 31];
        } else {
            return [62, 62, 62, 62];
        }
    }

    moveMapCenterTo(coords, officeZoom) {
        if (this.isMobile()) {
            if (!isNaN(officeZoom)) {
                this.map.setCenter(coords, officeZoom);
            } else {
                this.map.setCenter(coords, 16);
            }
        } else {
            if (!isNaN(officeZoom) && this.map.getZoom() !== officeZoom) {
                this.map.panTo(
                    coords, {
                        duration: 1500,
                        flying: true,
                        useMapMargin: true,
                    },
                ).then(() => {
                    this.map.setZoom(officeZoom, {duration: 500})
                });
            } else {
                this.map.panTo(
                    coords, {
                        duration: 1500,
                        flying: true,
                        useMapMargin: true,
                    },
                );
            }
        }
    }

    refreshMap() {
        if (this.isMobile() || this.isTablet()) {
            const mapTabElement = document.querySelector('[data-tab-link][href=\'#map\']');
            if (mapTabElement) {
                mapTabElement.click();
                scrollToTarget(mapTabElement);
                this.map.container.fitToViewport();
            }
        }
    }

    onListElementClick(office) {
        this.activateOffice(office);
    }

    deactivateOffice() {
        if (this.activeOffice) {
            this.activeOffice.getPlacemark().options.set({
                iconImageSize: this.iconOptions.iconImageSize,
                iconImageOffset: this.iconOptions.iconImageOffset,
            });
            this.activeOffice = null;
        }
    }

    activateOffice(office) {
        this.deactivateOffice();
        office.getPlacemark().options.set({
            iconImageSize: this.iconOptions.iconImageSize,
            iconImageOffset: this.iconOptions.iconImageOffset,
        });
        this.refreshMap();
        this.moveMapCenterTo([office.getLat(), office.getLng()], parseInt(office.settings.zoom, 10));
        this.activeOffice = office;
    }
}
import Jax from "../../components/jax/jax";
import {debounce} from "lodash";

const searchButton = document.querySelector('[data-search-button]');
const searchForm = document.querySelector('[data-search-form]');
const navList = document.querySelector('[data-header-nav]');
const dataContainer = searchForm.parentNode.querySelector('[data-search-container]');
const dataSearchCloseButton = document.querySelector('[data-search-close-button]');

if (dataSearchCloseButton) {
    dataSearchCloseButton.addEventListener('click', () => {
        searchForm.classList.toggle('_active');
        dataContainer.classList.toggle('_hidden');
        setTimeout(clearSearchResults, 200);
    })
}

if (searchButton) {
    searchButton.addEventListener('click', () => {
        searchForm.querySelector("input").focus();
        searchForm.classList.toggle('_active');
        searchForm.parentElement.classList.toggle('_hidden');
        if (searchButton.parentNode.parentNode.querySelector('[data-search-logo]')) {
            searchButton.parentNode.parentNode.querySelector('[data-search-logo]').classList.toggle('_hidden');
        }
        if (searchButton.parentNode.parentNode.querySelector('[data-search-badge]')) {
            searchButton.parentNode.parentNode.querySelector('[data-search-badge]').classList.toggle('_hidden');
        }
        navList.classList.toggle('_visible');
        dataContainer.classList.toggle('_hidden');
        setTimeout(clearSearchResults, 200);
    });
}

const moreButtons = document.querySelectorAll('[data-ajax-search-more]').forEach((item) => {
    item.addEventListener('click', (e) => {
        e.preventDefault();
        const jax = new Jax(item.getAttribute('href'));
        jax.send().then(({ data, request }) => {
            let page = document.createElement('div');
            page.classList.add('search-results__items');
            page.innerHTML = data;

            // Добавляем элементы в новую страницу
            const currentData = item.parentNode.parentNode.querySelector('[data-search-items]');
            Array.from(page.children).forEach((child) => {
                currentData.append(child);
            });

            const lastPage = request.getResponseHeader('x-page-last');
            const nextPage = request.getResponseHeader('x-page-next');

            if (lastPage) {
                // Удаляем ссылку на следующую страницу
                item.remove();
            } else {
                // Устанавливаем в ссылку номер другой страницы
                const href = item.getAttribute('href');
                const nextPageHref = href.replace(/page=\d+/, [
                    'page', nextPage
                ].join('='));

                item.setAttribute(
                    'href',
                    nextPageHref
                );
            }
        })
    });
});

if (searchForm) {
    searchForm.addEventListener('input', debounce(() => {
        const jax = new Jax(searchForm.getAttribute('data-simple-search'));
        jax.send(new FormData(searchForm)).then((html) => {
            dataContainer.innerHTML = html.data;

            if (!searchForm.querySelector("input").value) {
                dataContainer.innerHTML = "";
            }

            let event = new Event('DOMContentMutated');
            document.dispatchEvent(event);
        });


    }, 200));
}

function clearSearchResults() {
    dataContainer.innerHTML = "";
    searchForm.querySelector("input").value = "";
}


document.addEventListener('DOMContentMutated', () => {
    const showAllButton = document.querySelector('[data-simple-search-show-all]');

    if (showAllButton) {
        showAllButton.addEventListener('click', (e) => {
            searchForm.submit();
        });
    }
});
import { throttle } from 'lodash';
import { gsap } from 'gsap';

const bind = () => {
  const header = document.querySelector('header');
  const secondHeader = document.querySelector('.header__surface');
  const menuButtons = document.querySelectorAll('[data-menu-toggle]');
  const categoriesList = secondHeader.querySelector('.menu__categories-list');
  const plate = document.querySelector('[data-menu-plate]');
  const menuNames = secondHeader.querySelectorAll('[data-menu-name]');

  menuNames.forEach((menuName) => {
    menuName.addEventListener('mouseover', () => {
      menuNames.forEach((item) => {
        gsap.to(item, {
          color: '',
          duration: 0.3,
        });
      });
    });
  });
  const menuOpen = (height) => {
    gsap.to(menuNames, {
      color: '#000',
    });
    gsap.to(secondHeader, {
      opacity: '',
      duration: 0,
      onComplete: () => {
        plate.style.transitionTimingFunction = 'cubic-bezier(0.52, 0.15, 0.25, 0.8)';
        plate.style.transitionDuration = '0.9s';
        secondHeader.classList.add('_active');
        plate.classList.add('_active');
        categoriesList.classList.add('_active');
      },
    });
    document.body.style.overflow = 'hidden';
    gsap.fromTo('[data-menu-name]', {
      y: height,
    }, {
      y: 0,
      stagger: 0.03,
      duration: 0.2,
      delay: 1.05,
    });
    gsap.fromTo('[data-menu-link]', {
      borderBottomColor: 'transparent',
    }, {
      borderBottomColor: '#D0D0D1',
      duration: 0.6,
      delay: 1.1,
    });
  };
  const menuClose = (height) => {
    document.body.style.overflow = '';
    categoriesList.classList.remove('_active');
    gsap.fromTo('[data-menu-link]', {
      borderBottomColor: '#D0D0D1',
    }, {
      borderBottomColor: 'transparent',
      duration: 0.6,
    });
    gsap.fromTo('[data-menu-name]', {
      y: 0,
    }, {
      y: height,
      stagger: {
        from: 'end',
        each: 0.03,
      },
      duration: 0.2,
      onComplete: () => {
        plate.classList.remove('_active');
        gsap.to(secondHeader, {
          opacity: 0,
          duration: 0.25,
          onComplete: () => {
            secondHeader.classList.remove('_active');
          },
        });
      },
    });
  };

  const menuToggler = () => {
    header.classList.toggle('_active');
    let height = 0;
    if (window.innerWidth >= 1200) {
      height = window.innerWidth / 1675 * 35;
    } else {
      if (window.innerWidth >= 768) {
        height = window.innerWidth / 768 * 28;
      } else {
        height = 26;
      }
    }
    if (header.classList.contains('_active')) {
      menuOpen(height);
    } else {
      menuClose(height);
    }
  };

  const menuToggle = throttle(menuToggler, 2100);
  menuButtons.forEach((menuButton) => {
    menuButton.addEventListener('click', () => {
      menuToggle();
    });
  });
};

bind();
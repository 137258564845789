class TgWidget {
  constructor(tgWidget) {
    this.tgWidget = tgWidget;
    this.closer = undefined;
    this.init();
  }

  init() {
    this.closer = this.tgWidget.querySelector('[data-tg-widget-closer]');
    if (this.closer) {
      this.checkVisible();
      this.addListeners();
    }
  }

  addListeners() {
    this.closer.addEventListener('click', () => {
      this.removeWidget();
    });
  }

  removeWidget() {
    if (this.tgWidget) {
      this.tgWidget.remove();
    }
  }

  checkVisible() {
    const interval = setInterval(() => {
      if (this.tgWidget.querySelector('iframe')) {
        this.closer.classList.add('_visible');
        clearInterval(interval);
      }
    }, 500);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const tgWidget = document.querySelector('[data-tg-widget]');

  if (tgWidget) {
    new TgWidget(tgWidget);
  }
});
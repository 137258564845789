import autosize from 'autosize/dist/autosize';

const handler = function floatingFieldHandler() {
  autosize(document.querySelectorAll('textarea'));
};

function getPrepareTimeout() {
  let timeout = 0;
  if (process.env.NODE_ENV !== 'production') {
    timeout = 1500;
  }
  return timeout;
}

function initAll() {
  setTimeout(() => {
    handler();
  }, getPrepareTimeout());
}

window.addEventListener('DOMContentLoaded', initAll);
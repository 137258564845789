const bind = () => {
  const scrollableList = document.querySelector('[data-scrollable-list]');
  const scrollableMenu = document.querySelectorAll('[data-scrollable-link]');
  if (scrollableList) {
    const scrollableItems = scrollableList.querySelectorAll('[data-scrollable-item]');
    scrollableList.style.transitionDuration = '700ms';

    const activeItem = (link, items, container) => {
      items.forEach((item) => {
        if (link.dataset.scrollableLink === item.dataset.scrollableItem) {
          item.classList.add('_active');
          container.style.height = `${item.offsetHeight}px`;
        }
      });
    };

    const removeClasses = (items) => {
      items.forEach((item) => {
        item.classList.remove('_active');
      });
    };

    if (scrollableMenu) {
      scrollableMenu.forEach((link) => {
        link.addEventListener('click', () => {
          removeClasses(scrollableMenu);
          removeClasses(scrollableItems);
          activeItem(link, scrollableItems, scrollableList);
          link.classList.add('_active');
        });
      });

      setTimeout(() => {
        activeItem(scrollableMenu[0], scrollableItems, scrollableList);
      }, 500);
    }
  }
};


bind();
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-show-password]').forEach((eye) => {
    eye.addEventListener('click', (e) => {
      e.preventDefault();
      switch (eye.parentNode.querySelector('input').type) {
        case 'password':
          eye.parentNode.querySelector('input').type = 'text';
          break;
        case 'text':
          eye.parentNode.querySelector('input').type = 'password';
      }
    });
  });
});
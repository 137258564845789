const alignment = () => {
  const cards = document.querySelectorAll('[data-product-card]');
  let heights = [];

  cards.forEach((card) => {
    const title = card.querySelector('[data-product-card-title]');
    heights.push(title.offsetHeight);
  });

  const maxHeight = Array.max(heights);

  cards.forEach((card) => {
    const title = card.querySelector('[data-product-card-title]');
    const height = title.offsetHeight;
    card.style.paddingBottom = `${maxHeight - height}px`;
  });
};

window.addEventListener('load', alignment);
window.addEventListener('resize', alignment);

Array.max = (array) => {
  return Math.max.apply( Math, array );
};
import { gsap } from 'gsap';
import {ScrollMagic, ScrollScene} from "scrollscene";


const displayBlocks = document.querySelectorAll('[data-display]');
const circuitBlocks = document.querySelectorAll('[data-circuit]');
const controller = new ScrollMagic.Controller();

/**
 * data-display
 * data-display-****
 * **** - left, top, opacity, split, scale,
 * data-display-duration
 * data-display-delay
 *
 * data-circuit
 * data-circuit-child
 * data-circuit-duration
 * data-circuit-delay
 *
 */

const resize = (param) => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1200) {
        param = param / 1675 * windowWidth;
    } else {
        if (windowWidth >= 768) {
            param = param / 768 * windowWidth / 1.5;
        } else {
            param = param / 375 * windowWidth / 2;
        }
    }
    return param;
};

displayBlocks.forEach((block) => {
    const duration = block.dataset.displayDuration ? block.dataset.displayDuration : 600;
    const delay = block.dataset.displayDelay ? block.dataset.displayDelay : 0;
    let translateY = block.dataset.displayTop ? block.dataset.displayTop : block.dataset.displayTop === '' ? 20 : 0;
    let translateX = block.dataset.displayLeft ? block.dataset.displayLeft : block.dataset.displayLeft === '' ? 20 : 0;
    const opacity = block.dataset.displayOpacity === '' ? 0 : 1;
    const offset = block.dataset.displayOffset ? block.dataset.displayOffset : 0;
    const scale = block.dataset.displayScale ? block.dataset.displayScale : block.dataset.displayScale === '' ? 1.2 : 1;
    const origin = scale ? 'left top' : '';
    let tl = gsap.timeline();

    translateY = resize(translateY);
    translateX = resize(translateX);

    tl.from(block, {
        duration: duration/1000,
        delay: delay/1000,
        opacity: opacity,
        y: translateY,
        x: translateX,
        ease: 'power3',
        scale: scale,
        transformOrigin: origin,
        onComplete: () => {
            tl.clear();
        },
    });
    const sceneCircle = new ScrollScene({
        triggerElement: block,
        offset: offset - translateY,
        triggerHook: 0.7,
        gsap: {
            timeline: tl,
        },
    });
    sceneCircle.Scene
        //.addIndicators()
        .addTo(controller);
});

circuitBlocks.forEach((triggerBlock) => {
    let childBlocks = triggerBlock.querySelectorAll('[data-circuit-child]');
    const offset = triggerBlock.dataset.displayOffset ? triggerBlock.dataset.displayOffset : 0;

    let tl = gsap.timeline();
    let length = childBlocks.length;

    for (let i = 0; i < length; i++) {
        childBlocks.forEach((childBlock) => {
            if (+childBlock.dataset.circuitChild === i + 1) {
                const duration = childBlock.dataset.displayDuration ? childBlock.dataset.displayDuration : 600;
                const delay = childBlock.dataset.displayDelay ? childBlock.dataset.displayDelay : 0;
                let translateY = childBlock.dataset.displayTop ? childBlock.dataset.displayTop : childBlock.dataset.displayTop === '' ? 20 : 0;
                let translateX = childBlock.dataset.displayLeft ? childBlock.dataset.displayLeft : childBlock.dataset.displayLeft === '' ? 20 : 0;
                let split = childBlock.dataset.displaySplit ? childBlock.dataset.displaySplit : childBlock.dataset.displaySplit === '' ? 65 : '';
                const opacity = childBlock.dataset.displayOpacity === '' ? 0 : 1;
                const scale = childBlock.dataset.displayScale ? childBlock.dataset.displayScale : childBlock.dataset.displayScale === '' ? 1.2 : 1;
                const origin = scale ? 'left top' : '';

                if (split) {
                    split = resize(split);
                    tl.from(childBlock.querySelectorAll('[data-display-split-child]'), {
                        duration: duration / 1000,
                        y: split,
                        ease: 'power3',
                        stagger: 0.075,
                        onComplete: () => {
                            if (i === length - 1) {
                                tl.clear();
                            }
                        },
                    }, `=${delay/1000}`);
                } else {
                    translateY = resize(translateY);
                    translateX = resize(translateX);
                    tl.from(childBlock, {
                        duration: duration / 1000,
                        opacity: opacity,
                        y: translateY,
                        x: translateX,
                        scale: scale,
                        transformOrigin: origin,
                        ease: 'power3',
                        onComplete: () => {
                            if (i === length - 1) {
                                tl.clear();
                            }
                        },
                    }, `=${delay/1000}`);
                }
            }
        });
    }
    const sceneCircle = new ScrollScene({
        triggerElement: triggerBlock,
        triggerHook: 0.7,
        offse: offset,
        gsap: {
            timeline: tl,
        },
    });
    sceneCircle.Scene
        //.addIndicators()
        .addTo(controller);
});


import { gsap } from 'gsap';
import { ScrollScene } from 'scrollscene';
import { ScrollMagic } from 'scrollscene';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

const handle = () => {
  const controllerScroll = new ScrollMagic.Controller();
  const controllerClick = new ScrollMagic.Controller();
  const triggers = document.querySelectorAll('[data-menu-trigger]');
  const items = document.querySelectorAll('[data-menu-item]');
  const circleScroll = document.querySelector('[data-sticky-circle-scroll]');
  const circleClick = document.querySelector('[data-sticky-circle-click]');

  const getTranslateYValue = (element) => {
    const style = window.getComputedStyle(element)
    const matrix = style.transform || style.webkitTransform || style.mozTransform;
    if (matrix === 'none') {
      return 0;
    }
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ');
    return matrixValues[5];
  };


  const moveCircle = (key, trigger) => {
    const percent = (circleScroll.parentNode.offsetHeight + 16) * key / items.length;
    const tl = gsap.timeline();
    tl.to(circleScroll,
      {
        translateY: percent,
      });
    const sceneCircle = new ScrollScene({
      triggerElement: trigger,
      triggerHook: 0.45,
      offset: -150,
      duration: 300,
      gsap: {
        timeline: tl,
      },
    });
    sceneCircle.Scene
      //.addIndicators()
      .addTo(controllerScroll);
  };

  if (items) {
    // animation of circle && menu-item colors when scroll window
    items.forEach((item) => {
      triggers.forEach((trigger, key) => {
        if (item.dataset.menuItem === trigger.dataset.menuTrigger) {
          setTimeout(() => {
            const sceneText = new ScrollScene({
              triggerElement: trigger,
              triggerHook: 0.35,
              offset: 0,
              duration: trigger.offsetHeight,
            });
            sceneText.Scene
              .setClassToggle(item, '_active')
              .addTo(controllerClick);
              //.addIndicators();
            moveCircle(key, trigger);
          }, 800);
        }
      });
    });
    // scroll animation
    items.forEach((item) => {
      item.addEventListener('click', () => {
        triggers.forEach((trigger, key) => {
          if (trigger.dataset.menuTrigger === item.dataset.menuItem) {
            const percent = (circleClick.parentNode.offsetHeight + 16) * key / items.length;
            const tl = gsap.timeline();
            tl.to(circleScroll, {
              opacity: 0,
              duration: 0,
            }, '-=0.1')
              .to(circleClick,
                {
                  translateY: getTranslateYValue(circleScroll),
                  duration: 0,
                  opacity: 1,
                })
              .to(window,
                {
                  duration: 1,
                  scrollTo: {
                    y: trigger,
                    offsetY: 100,
                  },
                  ease: 'power1',
                })
              .to(circleClick,
                {
                  translateY: percent,
                  duration: 1,
                }, '-=1')
              .to(circleScroll, {
                translateY: percent,
                opacity: 1,
                duration: 0,
              })
              .to(circleClick, {
                opacity: 0,
                duration: 0,
              });
          }
        });
      }, { passive: true });
    });
  }
};

document.addEventListener('DOMContentLoaded', handle);
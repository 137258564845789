const links = document.querySelectorAll('[data-accordion-link]');

if(links[0]) {
  links.forEach((link) => {
    link.addEventListener('click', () => {
      const nextElem = link.nextElementSibling;
      const target = nextElem.querySelector('[data-accordion-target]');
      const targetHeight = target.offsetHeight;
      if (!link.classList.contains('_active')) {
        link.classList.add('_active');
        nextElem.style.height = `${targetHeight}px`;
      } else {
        link.classList.remove('_active');
        nextElem.style.height = '0px';
      }
    });
  });
}